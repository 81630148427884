export enum Mode {
  DAVR,
  DEMO,
  DEVELOPMENT
}

export interface EnvironmentModel {
  production: boolean;
  serverURL?: string;
  mode: Mode;
  fakeData?: boolean;
}
